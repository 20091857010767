import { createAlert } from '@gluestack-ui/alert';
import { Root, Text, Icon } from './styled-components';
const AccessibleAlert = createAlert({
    Root,
    Text,
    Icon,
});
export const Alert = AccessibleAlert;
export const AlertText = AccessibleAlert.Text;
export const AlertIcon = AccessibleAlert.Icon;
